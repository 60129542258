// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"
const theme = {
  styles: {
    global: (props) => ({
      fonts: {
        heading: `"Roboto",-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif`,
        body: `"Roboto",-apple-system,BlinkMacSystemFont,Helvetica,Arial,sans-serif`
      },
      body: {
        color: "#0E0F12"
      },
      h1: {
        fontSize: {base: '3xl', md: '5xl', lg: '6xl'},
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '1.1',
        wordWrap: 'initial',
        marginBottom: '1rem',
        position: 'relative',
        _before: {
          content: '""',
          position: 'absolute',
          left: '-15px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: '#81C90A',
          width: '5px',
          height: '67%'
        }
      },
      h2: {
        fontSize: {base: '2xl', md: '4xl', lg: '5xl'},
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '1.1',
        wordWrap: 'initial',
        marginBottom: '1rem',
        position: 'relative',
        _before: {
          content: '""',
          position: 'absolute',
          left: '-15px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: '#81C90A',
          width: '5px',
          height: '67%'
        }
      },
      h3: {
        fontSize: {base: 'xl', md: '3xl', lg: '4xl'},
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '1.1',
        wordWrap: 'initial',
        marginBottom: '1rem',
        position: 'relative',
        _before: {
          content: '""',
          position: 'absolute',
          left: '-15px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: '#81C90A',
          width: '5px',
          height: '67%'
        }
      },
      h4: {
        fontSize: {base: 'lg', md: '2xl', lg: '3xl'},
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '1.1',
        wordWrap: 'initial',
        marginBottom: '1rem',
        position: 'relative',
        _before: {
          content: '""',
          position: 'absolute',
          left: '-15px',
          top: '50%',
          transform: 'translateY(-50%)',
          background: '#81C90A',
          width: '5px',
          height: '67%'
        }
      },
      h5: {
        fontSize: {base: 'md', md: 'xl', lg: '2xl'},
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '1.1',
        wordWrap: 'initial',
        marginBottom: '1rem'
      },
      h6: {
        fontSize: {base: 'sm', md: 'md', lg: 'xl'},
        textTransform: 'uppercase',
        fontWeight: '700',
        lineHeight: '1.1',
        wordWrap: 'initial',
        marginBottom: '1rem'
      },
      main: {
        ul: {
          margin: `1.5rem 0 1.5rem 0`,
          // margin: 'revert',
          padding: 'revert',
          paddingLeft: '1.5rem',
          listStyle: 'none',
          listsStyleType: 'none',
          position: 'relative',
        },
        li: {
          marginBottom: '.30rem',
          fontSize: {base: 'md', md: 'lg', lg: 'xl'},
          position: 'relative',
          _before: {
            position: 'absolute',
            content: '"–"',
            color: '#111',
            marginLeft: '-1.1em',
          },
          a: {
            textDecoration: 'underline',
            cursor: 'pointer',
            _hover: {
              textDecoration: 'none'
            }
          },
          ul: {
            margin: '0',
          },
        },
        p: {
          marginBottom: 'revert'
        },
      },
      p: {
        fontSize: {base: 'md', md: 'lg', lg: 'xl'},
        lineHeight: '1.4',
        mb: {base: 3, md: 4, lg: 5, lx: 6},
        a: {
          textDecoration: 'underline',
          cursor: 'pointer',
          _hover: {
            textDecoration: 'none'
          }
        }
      }
    })
  },
  breakpoints: {
    sm: '360px',
    md: '768px',
    lg: '980px',
    xl: '1044px',
    '2xl': '1366px'
  }
}

export default extendTheme(theme)